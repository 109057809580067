import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Layout from './../../components/Layout'

export class view extends Component {
  render() {
    const passedData = this.props.location.state || {
      title: 'default title',
      description: 'default description',
      image: 'https://via.placeholder.com/350',
      preview: 'https://via.placeholder.com',
      repo:'https://www.github.com/confirm4crit'
    }
    const { title, description, image, preview,repo } = passedData
    return (
      <Layout>
        <Helmet>
          <title>{`${title} | Miles Robson`}</title>
        </Helmet>
        <Link to="/portfolio">&larr; back</Link>
        <h2>{title}</h2>
        <img src={image} />
        <div>
          {description}
          <br /> <br />
          {preview && (
            <div>
              Preview:{' '}
              <a href={preview} target="_new">
                {preview}
              </a>
            </div>
          )}
          {repo && (
            <div>
              Repo:{' '}
              <a href={repo} target="_new">
                {repo}
              </a>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default view
